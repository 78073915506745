
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const BruinLogo = ({ children }) => {
    const data = useStaticQuery(graphql`
      query {
        allWordpressWpMedia(filter: {title: {eq: "bruin-letter"}}) {
            nodes {
              source_url
            }
          }
      }
    `)

    let bruinLetter = data.allWordpressWpMedia.nodes[0].source_url;

    return (
        <>
         <Img src={bruinLetter} backgroundcolor={'#ffffff'} tabIndex={-1}/>
        </>
    )
}

export default BruinLogo
