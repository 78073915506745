import React from 'react'
import Img from "gatsby-image"
import ExternalButton from "../../components/master/buttons/externalButton"

export default function index({projectData, close}) {
  
  return (
    <div>
        <div
        className="popup__inner featured__wrapper"
        id={"popup__inner"}
        >
            <div className="triangle__big"></div>
            <div className="triangle__small"></div>
            
            <div className="featured__article" key={index}>
                <img
                className={"popup__inner__background"}
                src={
                    projectData.map.source_url
                }
                alt=""
                tabIndex={-1}
                />
                <div className="featured__artitle__inner">
                <div className="featured__article__top">
                    <div className="featured__article__title">
                    {projectData.title.length > 0 ? (
                        <h4>{projectData.title}</h4>
                    ) : (
                        ""
                    )}
                    </div>
                    {projectData.first_copy.length > 0 ? (
                    <div
                        className="featured__article__content home__pop__content"
                        dangerouslySetInnerHTML={{
                        __html: projectData.first_copy,
                        }}
                    />
                    ) : (
                    ""
                    )}
                </div>
                <div className="featured__article__divider"></div>
                {projectData.second_copy.length > 0 ? (
                    <div
                    className="featured__article__content"
                    dangerouslySetInnerHTML={{
                        __html: projectData.second_copy,
                    }}
                    />
                ) : (
                    ""
                )}
                </div>
                <div className="featured__article__bottom flex-end">
                <div className="featured__article__cta">
                    <ExternalButton
                    redirectionLink={projectData.button_link}
                    buttonText={projectData.button_text}
                    buttonClass={""}
                    ></ExternalButton>
                </div>
                </div>
            </div>
        </div>
        
        <button
        className="close"
        tabIndex="0"
        onClick={close}
        onKeyPress={close}
        id="close__menu"
        ></button>
    </div>
  )
}
