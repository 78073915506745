import React from 'react'
import Img from "gatsby-image"

export default function index({projectData, close}) {

  return (
    <div>
        <div className="popup__inner featured__wrapper popup__portfolio gam" id={'popup__team'}>
            <div className="triangle__big"></div>
            <div className="triangle__small"></div>
            <div className="featured__article row" >
                <div className={'col-md-12 col-lg-12'}>
                    {
                        projectData.popup_background 
                        ?<Img 
                            tabIndex={0}
                            src={projectData.popup_background.source_url}
                            alt={``}
                            className={'popup__background'}
                        />
                        : ""
                    }
                    {
                        projectData.case_background
                        ?<Img 
                            tabIndex={0}
                            src={projectData.case_background.source_url}
                            alt={``}
                            className={'popup__background'}
                        />
                        : ""
                    }
                    {
                        projectData.project_logo
                        ?<img className={'project__logo --floating'} src={projectData.project_logo.source_url} alt={'Client Logo'} tabIndex={0}/>
                        :""

                    }
                    {
                        projectData.icon
                        ?<img className={'project__logo --floating'} src={projectData.icon.source_url} alt={'Client Logo'} tabIndex={0}/>
                        :""

                    }
                </div>
                <div className={'col-md-12 col-lg-12'}>
                    <div className="featured__artitle__inner">
                        <div className="featured__article__top">
                        <div className={'col-md-12 col-lg-12'}>
                            <div className={'line'}></div>
                            {   
                                projectData.popup_content?.length > 0
                                ?<div className="featured__article__content" dangerouslySetInnerHTML={{__html: projectData.popup_content}} />
                                : ""
                            } 
                            {   
                                projectData.study_info?.length > 0
                                ?<div className="featured__article__content" dangerouslySetInnerHTML={{__html: projectData.study_info}} />
                                : ""
                            } 
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button className="close" tabIndex="0" onClick={close} onKeyPress={close}       id="close__menu" ></button>
    </div>
  )
}
