import React, { Component } from "react"
import Layout from "../layouts/index"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import axios from "axios"
import "./css/index.css"
import "./css/home.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from "gatsby-image"
import Popup from "reactjs-popup"
import ExternalButton from "../components/master/buttons/externalButton"
import InternalButton from "../components/master/buttons/internalButton"
import BruinLogo from "../components/master/bruin-letter-logo/bruinLetter"
import BasicPopup from "../components/basic-project-popup/index"
import CaseStudy from "../components/case-popup-inner/index"

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mixBlend: "mix-blend",
    }
  }

  formatDate(arr) {
    arr.forEach(post => {
      let parts = post.date.split("T")
      let dateCut = parts[0].split("-")
      let shortYear = dateCut[0].slice(2, 4)
      let newDate = `${dateCut[1]}.${dateCut[2]}.${shortYear}`
      post.date = newDate
    })
  }

  getNews() {
    const news = `${process.env.GATSBY_WP_API_LINK}/wp-json/wp/v2/news?per_page=12&_embed`
    axios.get(news).then(({ data }) => {
      this.formatDate(data)
      this.setState(
        {
          news: data,
        },
        () => {
        }
      )
    })
  }

  focusTrap() {
    // setTimeout(function() {
    //   document.getElementById("section-2").scrollIntoView()

    //   document.querySelectorAll("html")[0].classList.add("html__custom")
    //   // Focus the element on the burguer menu
    //   document.getElementById("close__menu").focus()

    //   // Trap the focus loop inside the menu
    //   var element = document.getElementById("popup__inner")
    //   var focusableEls = document.querySelectorAll(
    //     "#popup__inner .close, #popup__inner .btn-main a "
    //   )

    //   var firstFocusableEl = focusableEls[0],
    //     lastFocusableEl = focusableEls[focusableEls.length - 1],
    //     KEYCODE_TAB = 9

    //   element.addEventListener("keydown", function(e) {
    //     var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB
    //     if (!isTabPressed) {
    //       return
    //     }

    //     if (e.shiftKey) {
    //       /* shift + tab */ if (document.activeElement === firstFocusableEl) {
    //         lastFocusableEl.focus()
    //         e.preventDefault()
    //       }
    //     } /* tab */ else {
    //       if (document.activeElement === lastFocusableEl) {
    //         firstFocusableEl.focus()
    //         e.preventDefault()
    //       }
    //     }
    //   })

    //   document
    //     .querySelectorAll(".clients__logos")[0]
    //     .classList.remove("rotate__clients__logos")
    //   ;[...document.querySelectorAll(".clients__logos .col")].map(el =>
    //     el.classList.remove("rotated__col")
    //   )
    // }, 10)
  }

  endModal(e) {
    document.querySelectorAll("html")[0].classList.remove("html__custom")
    document.getElementById("clients__logos").classList.remove("show__element")
    e.preventDefault()
    if (e.type === "keypress") {
      if (e.which === 32 || e.which === 13) {
        ;[...document.querySelectorAll(".popup-overlay")][0].remove()
      }
    } else {
      ;[...document.querySelectorAll(".popup-overlay")][0].remove()
    }
  }

  changeBodyScroll() {
    // When the modal is hidden...
    document.querySelectorAll("html")[0].classList.remove("html__custom")
    document.getElementById("clients__logos").classList.remove("show__element")
    document
      .querySelectorAll(".clients__logos")[0]
      .classList.add("rotate__clients__logos")
    ;[...document.querySelectorAll(".clients__logos .col")].map(el =>
      el.classList.add("rotated__col")
    )
  }

  triggerClientPopUp(event) {
    const idPopup = event.target.getAttribute("datatracknumber")

    document.getElementById(idPopup).click()

    if (window.innerWidth < 768) {
      document.getElementById("clients__logos").classList.add("show__element")
    }
  }

  componentDidMount() {
    setTimeout(function() {
      var linkMont = document.createElement("link")
      linkMont.id = "montserrat__font"
      linkMont.rel = "stylesheet"
      linkMont.href =
        "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap"
      document.head.appendChild(linkMont)

      var linkMul = document.createElement("link")
      linkMul.id = "muli__font"
      linkMul.rel = "stylesheet"
      linkMul.href =
        "https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800;900&display=swap"
      document.head.appendChild(linkMul)
    }, 100)

    this.getNews()
  }
  
  render() {
    // This variable will return all the fields related to the post
    const pageData = this.props.data.allWordpressPage.edges[0].node,
          newsData = this.state.news
    //Slick Setting
    const settings = {
      speed: 500,
      cssEase: "linear",
      autoplay: true,
      autoplaySpeed: 9000,
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }

    let settingsClients = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content={pageData.yoast_meta.yoast_wpseo_metadesc}
          />
          <title>Home | Bruin Capital</title>
          <link
            rel="canonical"
            href={pageData.yoast_meta.yoast_wpseo_canonical}
          />
        </Helmet>
        <div className="home__page">
          <section className="container-fluid hero-bg media__featured">
            {/*
            <a href="#section-2" tabIndex={0} className="down__arrow"><div  tabIndex={-1} aria-hidden="true"></div><span className={'opacity-0'} tabIndex={-1}>Scroll down link</span></a> */}
            {pageData.featured_media ? (
              <div className="page__background" aria-hidden="true">
                <img
                  src={
                    pageData.featured_media.source_url
                  }
                  alt={""}
                  tabIndex={-1}
                />
              </div>
            ) : (
              " "
            )}
            <div className="container-fluid home_first">
              <div className="home_inner">
                {pageData.acf.main_copy.length > 0 ? (
                  <div
                    tabIndex={0}
                    className="header__copy text-left"
                    dangerouslySetInnerHTML={{ __html: pageData.acf.main_copy }}
                  />
                ) : (
                  ""
                )}
                <div className="featured__article__divider home__divider"></div>
                {pageData.acf.main_second_copy.length > 0 ? (
                  <div
                    tabIndex={0}
                    className="home__copy text-left"
                    dangerouslySetInnerHTML={{
                      __html: pageData.acf.main_second_copy,
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            {pageData.acf.header_logos ? (
              <div className="container-fluid logos">
                <div className="clients__logos__wrapper">
                  <div className="clients__slider">
                    <Slider {...settingsClients}>
                      {pageData.acf.header_logos.map((element, index) => (
                        <div>
                          <img
                            datatracknumber={`logo-client-${index}`}
                            id={`logo-client-${index}-s`}
                            tabIndex={0}
                            src={element.icon.source_url}
                            alt={`Client ${element.title} logo`}
                            onClick={this.triggerClientPopUp}
                            onKeyPress={this.triggerClientPopUp}
                            key={`${index}-logo`}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div
                    className="row clients__logos rotate__clients__logos"
                    id={"clients__logos"}
                  >
                    {pageData.acf.header_logos.map((element, index) => (
                      <div
                        className="col rotated__col home__case__study"
                        data-popuptype={pageData.acf.popup_type}
                        key={`header_logos-${element}-${index}`}
                      >
                        {
                          element.popup_type === "popup"
                          ? 
                          <Popup
                            onOpen={this.focusTrap}
                            onClose={this.changeBodyScroll}
                            modal
                            closeOnEscape
                            closeOnDocumentClick
                            trigger={
                              <img
                                style={{ display: "none" }}
                                id={`logo-client-${index}`}
                                datapopuptype={pageData.acf.popup_type}
                                tabIndex={0}
                                src={element.icon.source_url}
                                alt={`Client ${element.title} logo`}
                                onKeyPress={this.triggerClientPopUp}
                                datatracknumber={`logo-client-${index}`}
                                loading="lazy"
                              />
                            }
                            position="center center"
                          >
                            {close => (
                              <BasicPopup projectData={element} close={close} />
                            )}
                          </Popup>
                          :
                          <Popup
                            onOpen={this.focusTrap}
                            onClose={this.changeBodyScroll}
                            modal
                            closeOnEscape
                            closeOnDocumentClick
                            trigger={
                              <img
                                style={{ display: "none" }}
                                id={`logo-client-${index}`}
                                tabIndex={0}
                                src={element.icon.source_url}
                                alt={`Client ${element.title} logo`}
                                onKeyPress={this.triggerClientPopUp}
                                datatracknumber={`logo-client-${index}`}
                                loading="lazy"
                              />
                            }
                            position="center center"
                          >
                            {close => (
                              <CaseStudy projectData={element} close={close} />
                            )}
                          </Popup>
                        }

                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </section>
          <section className="our__approach container-fluid" id="section-2">
            <div className="floating__letter">
              <BruinLogo />
            </div>
            <div className="container">
              <div className="row">
                <div className={"col-md-12 col-lg-9"}>
                  {pageData.acf.our_approach_title.length > 0 ? (
                    <h2
                      className={"text-black"}
                      dangerouslySetInnerHTML={{
                        __html: pageData.acf.our_approach_title,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {pageData.acf.our_approach_content.length > 0 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageData.acf.our_approach_content,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {pageData.acf.our_approach_button_link ? (
                    <InternalButton
                      buttonText={pageData.acf.our_approach_button_text}
                      redirectionLink={pageData.acf.our_approach_button_link}
                    ></InternalButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="our__approach featured__section container-fluid">
            {pageData.acf.featured_background ? (
              <img
                className={"img__background"}
                src={
                  pageData.acf.featured_background.source_url
                }
                alt=""
                tabIndex={-1}
              />
            ) : (
              ""
            )}
            <div className="container">
              <div className="row">
                <div className={"col-md-12 col-lg-4 col-xl-6 text-left"}>
                  {pageData.acf.featured_title.length > 0 ? (
                    <h2
                      className={"text-white"}
                      dangerouslySetInnerHTML={{
                        __html: pageData.acf.featured_title,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {pageData.acf.featured_content.length > 0 ? (
                    <div
                      className="pyne-panel"
                      dangerouslySetInnerHTML={{
                        __html: pageData.acf.featured_content,
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {pageData.acf.featured_button_link ? (
                    <ExternalButton
                      buttonText={pageData.acf.featured_button_text}
                      redirectionLink={
                        pageData.acf.featured_button_link.source_url
                      }
                    ></ExternalButton>
                  ) : (
                    ""
                  )}
                </div>
                <div className={"col-md-12 col-lg-8 col-xl-6 "}></div>
              </div>
            </div>
          </section>
          {newsData ? (
            <section className="container-fluid latest__news">
              <div className="container">
                {pageData.acf.latest_news_title.length > 0 ? (
                  <h2
                    className={"text-center text-black "}
                    dangerouslySetInnerHTML={{
                      __html: pageData.acf.latest_news_title,
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="latest__news__wrapper">
                <Slider
                  className="row featured__wrapper dark latest__news__wrapper"
                  {...settings}
                >
                  {newsData.map((element, index) =>
                    element.acf ? (
                      <div
                        className={`latest__article`}
                        key={`newsData-${element}-${index}`}
                      >
                        {element.categories.length > 0 && element.categories !== null && element.hasOwnProperty('_embedded') && element._embedded.hasOwnProperty('wp:term') ? (
                          <div className={"title"}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: element._embedded["wp:term"][0][0].name,
                              }}
                            />
                          </div>
                        ) : (
                          <div className={"title"}>
                            <p>In The News</p>
                          </div>
                        )}
                        <div className={"date__title"}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: element.date,
                            }}
                          />
                        </div>

                        {element.title.rendered.length > 0 ? (
                          <p
                            className={"content"}
                            dangerouslySetInnerHTML={{
                              __html: element.title.rendered,
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {element.acf !== null ? (
                          <>
                            {element.acf.media_file ? (
                              <ExternalButton
                                buttonClass={""}
                                buttonText={"Read More"}
                                redirectionLink={element.acf.media_file}
                              ></ExternalButton>
                            ) : (
                              <>
                                {element.acf.external_link_file !== null ? (
                                  <ExternalButton
                                    buttonClass={""}
                                    buttonText={"Read More"}
                                    redirectionLink={
                                      element.acf.external_link_file
                                    }
                                  ></ExternalButton>
                                ) : (
                                  <>
                                    {element.acf.external_link_file.length >
                                    0 ? (
                                      <ExternalButton
                                        buttonClass={""}
                                        buttonText={"Read More"}
                                        redirectionLink={
                                          element.acf.external_link_file
                                        }
                                      ></ExternalButton>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </Slider>
              </div>
            </section>
          ) : (
            ""
          )}
        </div>
      </Layout>
    )
  }
}
export default HomePage

export const pageQuery = graphql`
  query HomeQuery {
    allWordpressPage(filter: { path: { eq: "/" } }) {
      edges {
        node {
          id
          title
          content
          date(formatString: "MMMM DD, YYYY")
          yoast_meta {
            yoast_wpseo_metadesc
            yoast_wpseo_title
            yoast_wpseo_canonical
          }
          featured_media {
            source_url
          }
          acf {
            featured_button_link {
              source_url
            }

            featured_button_text
            featured_content
            featured_title
            latest_news_title
            main_copy
            main_second_copy
            our_approach_button_link
            our_approach_button_text
            our_approach_content
            our_approach_title
            header_logos {
              popup_type
              case_background{
                source_url
              }
              study_info
              button_link
              button_text
              first_copy
              second_copy
              title
              map {
                source_url
              }
              icon {
                source_url
              }
            }
            featured_background {
              source_url
            }
          }
        }
      }
    }
    allWordpressWpNews(limit: 12) {
      edges {
        node {
          acf {
            external_link_file
            external_news_link
            media_file {
              source_url
            }
          }
          title
          content
          categories {
            name
          }
          date(formatString: "MM.DD.YYYY")
        }
      }
    }
  }
`
